<template>
  <div class="chat-container">
    <div class="chat-header">
      <h2 class="chat-title">
        {{ threadInfo.subject }} ({{ messages.length }})
        <small v-if="threadInfo.type === 'sent' || threadInfo.type === 'draft'">
          From {{ profile.name }} to {{ threadInfo.from.name }}, started on
          {{ getDateValues(threadInfo.date) }}
        </small>
        <small v-else>
          From {{ threadInfo.from.name }} to {{ profile.name }}, started on
          {{ getDateValues(threadInfo.date) }}
        </small>
      </h2>
    </div>
    <hr />
    <div class="message-list">
      <div v-for="(item, index) in messages" :key="index" class="message-card">
        <div class="message-header">
          <template v-if="item.type === 'sent' || item.type === 'draft'">
            {{ profile.name }} ({{ profile.emailAddress }})
          </template>
          <template v-else>
            {{ item.from.name }} ({{ item.from.email }})
          </template>
          <span class="message-time">{{ getDateValues(item.date) }}</span>
        </div>

        <div class="message-meta">
          <div class="meta-info">
            <div v-if="item.to && item.to.length">
              <strong>To:</strong>
              {{ formatAddresses(item.to) }}
            </div>
            <div v-if="item.cc && item.cc.length">
              <strong>Cc:</strong>
              {{ formatAddresses(item.cc) }}
            </div>
          </div>
          <button class="reply-button-top" title="Reply" @click="openReply(index, item)">
            <i class="fa-solid fa-reply"></i>
          </button>
        </div>

        <div v-if="replyOpenIndex === index" class="reply-container">
          <el-row :gutter="20" class="reply-fields">
            <el-col :span="24" class="field-group subject">
              <label>Subject:</label>
              <input
                type="text"
                v-model="replySubject"
                class="full-width-input"
              />
            </el-col>

            <!-- To Emails as tags -->
            <el-col :span="24" class="field-group">
              <label>To:</label>
              <div class="attendees-group" @click="focusInput('toInput')">
                <el-tag
                  v-for="(email, index) in replyTo"
                  :key="'to-' + index"
                  closable
                  @close="removeEmail('replyTo', index)"
                  type="success"
                  size="mini"
                  style="margin-right: 5px; cursor: pointer"
                  tabindex="0"
                >
                  {{ email }}
                </el-tag>
                <span
                  ref="toInput"
                  contenteditable="true"
                  class="editable-span"
                  @keydown.enter.prevent="addEmail('replyTo', $event)"
                  @blur="addEmail('replyTo', $event)"
                ></span>
              </div>
              <p v-if="toError" class="error-message">{{ toError }}</p>
            </el-col>

            <!-- Cc Emails as tags -->
            <el-col :span="24" class="field-group">
              <label>Cc:</label>
              <div class="attendees-group" @click="focusInput('ccInput')">
                <el-tag
                  v-for="(email, index) in replyCc"
                  :key="'cc-' + index"
                  closable
                  @close="removeEmail('replyCc', index)"
                  type="success"
                  size="mini"
                  style="margin-right: 5px; cursor: pointer"
                  tabindex="0"
                >
                  {{ email }}
                </el-tag>
                <span
                  ref="ccInput"
                  contenteditable="true"
                  class="editable-span"
                  @keydown.enter.prevent="addEmail('replyCc', $event)"
                  @blur="addEmail('replyCc', $event)"
                ></span>
              </div>
              <p v-if="ccError" class="error-message">{{ ccError }}</p>
            </el-col>

            <!-- Bcc Emails as tags -->
            <el-col :span="24" class="field-group">
              <label>Bcc:</label>
              <div class="attendees-group" @click="focusInput('bccInput')">
                <el-tag
                  v-for="(email, index) in replyBcc"
                  :key="'bcc-' + index"
                  closable
                  @close="removeEmail('replyBcc', index)"
                  type="success"
                  size="mini"
                  style="margin-right: 5px; cursor: pointer"
                  tabindex="0"
                >
                  {{ email }}
                </el-tag>
                <span
                  ref="bccInput"
                  contenteditable="true"
                  class="editable-span"
                  @keydown.enter.prevent="addEmail('replyBcc', $event)"
                  @blur="addEmail('replyBcc', $event)"
                ></span>
              </div>
              <p v-if="bccError" class="error-message">{{ bccError }}</p>
            </el-col>
          </el-row>

          <div class="attachment-section">
            <label>Attachments:</label>
            <input type="file" multiple @change="handleFileChange" />
            <div class="attachments-list" v-if="attachments.length">
              <div
                v-for="(file, idx) in attachments"
                :key="idx"
                class="attachment-item"
              >
                <span>{{ file.name }}</span>
                <button @click="removeAttachment(idx)">Remove</button>
              </div>
            </div>
          </div>

          <div class="reply-editor">
            <ckeditor
              v-model="replyText"
              :config="editorConfig"
              :key="editorKey"
            ></ckeditor>
          </div>

          <div class="reply-buttons">
            <button @click="sendReply()" class="send-button">Send</button>
            <button @click="closeReply" class="cancel-button">Cancel</button>
          </div>
        </div>

        <div class="message-text">
          <div v-html="getContent(item?.contentInHtml)" class="message"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
import moment from "moment";
import { sendReplytoTheMessage } from "../user-integrations/KF-mail/data/messages";

export default {
  props: {
    threadInfo: {
      type: Object,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
    companyConfig: {
      type: Object,
      required: true,
    },
    userConfig: {
      type: Object,
      required: true,
    },
    sendMail:{
      type: Function,
      reruired:true
    }
  },
  data() {
    return {
      replyOpenIndex: null,
      currentMessageId: null,
      replySubject: "",
      replyTo: [],
      replyCc: [],
      replyBcc: [],
      newReplyTo: "",
      newReplyCc: "",
      newReplyBcc: "",
      toError: "",
      ccError: "",
      bccError: "",
      replyText: "",
      attachments: [],
      editorConfig: JSON.parse(JSON.stringify(ckEditorConfig)),
      editorKey: 0,
    };
  },
  methods: {
    focusInput(refName) {
      this.$refs[refName].focus();
    },
    addEmail(field, event) {
      const input = event.target.innerText.trim();
      if (!this.validateEmail(input)) {
        this[`${field}Error`] = "Invalid email format.";
        return;
      }
      if (input && !this[field].includes(input)) {
        this[field].push(input);
        this[`${field}Error`] = "";
      }
      event.target.innerText = ""; // Clear input field
    },

    removeEmail(field, index) {
      this[field].splice(index, 1);
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    formatAddresses(addresses) {
      if (!addresses || !Array.isArray(addresses)) return "";
      return addresses
        .map((addr) => {
          let name, addressStr;
          if (addr.emailAddress) {
            name = addr.emailAddress.name;
            addressStr = addr.emailAddress.address;
          } else {
            name = addr.name;
            addressStr = addr.address;
          }
          return name ? `${name} <${addressStr}>` : addressStr;
        })
        .join(", ");
    },
    parseEmailList(addressStr) {
      if (!addressStr) return [];
      return addressStr.split(",").map((entry) => {
        const match = entry.match(/<([^>]+)>/);
        return match ? match[1].trim() : entry.trim();
      });
    },
    openReply(index, message) {
      this.replyOpenIndex = index;
      this.editorKey++;
      this.replySubject = `Re: ${this.threadInfo.subject}`;

      if (message.to && message.to.length) {
        this.replyTo = this.parseEmailList(
          this.formatAddresses(message.to)
        ).map((email) => email);
      } else {
        this.replyTo = message.from?.email
          ? [{ text: message.from.email }]
          : [];
      }

      if (message.cc && message.cc.length) {
        this.replyCc = this.parseEmailList(
          this.formatAddresses(message.cc)
        ).map((email) => email);
      } else {
        this.replyCc = [];
      }

      this.replyBcc = [];
      this.replyText = "";
      this.attachments = [];
      this.currentMessageId = message.messageId;
    },
    closeReply() {
      this.replyOpenIndex = null;
      this.replySubject = "";
      this.replyTo = [];
      this.replyCc = [];
      this.replyBcc = [];
      this.replyText = "";
      this.attachments = [];
    },
    async sendReply() {
      if (this.replyText.trim() === "") {
        alert("Reply body cannot be empty!");
        return;
      }
      const formData = new FormData();
      formData.append("subject", this.replySubject);
      formData.append("message", this.replyText);
      formData.append("to", JSON.stringify(this.replyTo));
      formData.append("cc", JSON.stringify(this.replyCc));
      formData.append("bcc", JSON.stringify(this.replyBcc));
      if (this.attachments && this.attachments.length > 0) {
        this.attachments.forEach((file) => {
          formData.append(`attachments`, file.raw, file.name);
        });
      }
      try {
        let response = await sendReplytoTheMessage(
          this.companyConfig?.service,
          this.userConfig?._id,
          formData,
          this.currentMessageId
        );
        if (response.email.status) {
          this.$message({
            message: "Reply Mail Sent Succeessfully",
            type: "success",
          });
          this.sendMail(true);
          this.closeReply()
        } else {
          this.$message({
            message: "failed to send Mail",
            type: "error",
          });
        }
      } catch (err) {
        console.log("errrr", err);
      }
    },
    addTag(list) {
      const email =
        list === this.replyTo
          ? this.newReplyTo
          : list === this.replyCc
          ? this.newReplyCc
          : this.newReplyBcc;
      if (email && !list.includes(email)) {
        list.push(email);
        if (list === this.replyTo) this.newReplyTo = "";
        if (list === this.replyCc) this.newReplyCc = "";
        if (list === this.replyBcc) this.newReplyBcc = "";
      }
    },
    removeTag(list, index) {
      list.splice(index, 1);
    },
    handleFileChange(event) {
      const files = event.target.files;
      this.attachments = Array.from(files).map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        raw: file,
      }));
      // Clear the input value to allow re-selecting the same file
      event.target.value = "";
    },
    removeAttachment(index) {
      this.attachments.splice(index, 1);
    },
  },
  computed: {
    getContent() {
      return (data) => {
        const patterns = [
          /^On .* wrote:/gm,
          /^-----Original Message-----/gm,
          /^From: .*$/gm,
          /^Sent: .*$/gm,
        ];
        let cleanedEmail = data;
        for (const pattern of patterns) {
          if (pattern.test(cleanedEmail)) {
            cleanedEmail = cleanedEmail.split(pattern)[0].trim();
            break;
          }
        }
        const splitMessage = data.split("\nOn ");
        return splitMessage[0].replace(/On.*wrote:/g, "").trim();
      };
    },
    getDateValues() {
      return (date) => moment(date).local().format("LLLL");
    },
  },
};
</script>

<style scoped>
/* Global Container */
.chat-container {
  margin: 5px auto;
  max-width: 800px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background: #fff;
  padding: 20px;
  height: 95vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.chat-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background: #fff;
  padding: 10px 0;
  z-index: 10;
}

.chat-title small {
  display: block;
  font-size: 14px;
  color: #777;
}

/* Horizontal Rule */
hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

/* Message List & Cards */
.message-list {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.message-card {
  background: #eaeaea;
  padding: 20px;
  border-radius: 8px;
  margin: 15px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
}

/* Message Header */
.message-header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  color: #555;
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
  margin-bottom: 12px;
}

.message-time {
  font-size: 12px;
  color: #999;
}

/* Metadata & Reply Button */
.message-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  margin-bottom: 12px;
  color: #555;
}

.meta-info div {
  margin-bottom: 6px;
}

.reply-button-top {
  /* background-color: #c3cbd4; */
  color: #cf0e37;
  border: none;
  padding: 8px 14px;
  cursor: pointer;
  font-size: 13px;
  border-radius: 4px;
  transition: background 0.3s;
}

.reply-button-top:hover {
  /* background-color: #0056b3; */
}

/* Reply Container */
.reply-container {
  margin: 15px 0;
  padding: 15px;
  background: #eef5ff;
  border: 1px solid #cce0ff;
  border-radius: 6px;
}

.reply-fields {
  margin-bottom: 15px;
}
.full-width-input {
  width: 100%;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width */
}

.field-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.field-group label {
  flex: 0 0 50px; /* Adjust width as needed */
  margin-right: 10px;
}

.attendees-group {
  border: 1px solid #dcdfe6;
  padding: 5px;
  min-height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: text;
  width: 100%;
}

.editable-span {
  min-width: 50px;
  padding: 5px;
  outline: none;
  border: none;
  display: inline-block;
  white-space: nowrap;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
/* Attachment Section */
.attachment-section {
  margin-bottom: 15px;
}

.attachment-section label {
  font-weight: bold;
  margin-right: 10px;
  color: #444;
}

.attachments-list {
  margin-top: 8px;
}

.attachment-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.attachment-item span {
  margin-right: 10px;
  font-size: 14px;
}

.attachment-item button {
  background: #d9534f;
  color: #fff;
  border: none;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  transition: background 0.3s;
}

.attachment-item button:hover {
  background: #c9302c;
}

/* CKEditor Reply Editor */
.reply-editor {
  margin-bottom: 15px;
}

/* Reply Buttons */
.reply-buttons {
  display: flex;
  gap: 15px;
}

.send-button,
.cancel-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 15px;
  transition: background 0.3s;
}

.cancel-button {
  background-color: #aaa;
}

.send-button:hover {
  background-color: #0056b3;
}

.cancel-button:hover {
  background-color: #888;
}

/* Message Content */
.message-text {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.message {
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-top: 12px;
  word-wrap: break-word;
}
</style>
